import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width: 40%;
  height: 50%;
  border-radius: 20px;
  box-shadow: 2px 2px 15px 2px lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p{
    color: #5c5c5c;
    font-weight: 500;
    font-size: 2rem;
  }
`;

export const CloseModalButton = styled.div`
  position: absolute;
  right: 15px;
  top: 20px;
`
export const Image = styled.img`
  width: 80%;
`
export const TextModal = styled.p`
  color: gray;
  font-weight: 500;
  font-size: 1.5rem;
`
export const InstructionButton = styled.button`
  border: none;
  width: 30%;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 2%;
  background-color: #001375;
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  &:hover{
    filter: opacity(.8);
  }
`

export const FileButton = styled.label`
  border: none;
  width: 30%;
  padding: 1rem;
  border-radius: 5px;
  margin-top: 2%;
  background-color: #001375;
  color: white;
  font-size: 1.12rem;
  font-weight: 500;
  box-sizing: border-box;
  cursor: pointer;
  &:hover{
    filter: opacity(.8);
  }
`

export const SendButton = styled.button`
  border: none;
  background-color: #DE0209;
  width: 30%;
  margin-top: 5%;
  padding: 1rem;
  border-radius: 5px;
  color: white;
  font-size: 1.12rem;
  font-weight: 500;
  cursor: pointer;
  &:hover{
    filter: opacity(.8);
  }
`



import styled from 'styled-components';



export const Manager = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 8rem;
  background-color: #F0F0F0;
  box-shadow:5px 5px 10px lightgray;
`

export const Buttom = styled.button`
  border-radius: 20px;
  background-color: #DE0209;
  color: #FFFFFF;
  width: 10%;
  padding: .5rem;
  border: none;
  font-size: 1.3rem;
  margin-right: 5rem;
`



export const Select = styled.select`
  width: 10%;
  border: none;
  background-color: #001375;
  padding: .5rem 0;
  color: #FFF;
  border-radius: 20px;
`

export const Showcase = styled.div`
  width: 60%;
  padding: 2rem 2rem;
  box-sizing: border-box;
  &:nth-child(odd){
    background-color: #F0F0F0;
    box-shadow: 5px 5px 10px lightgray;
  }
  &:nth-child(even){
    background-color: white;
    box-shadow: 5px 5px 10px lightgray;
  }
  margin-top: 1rem;
  border-radius: 20px;
  display: flex;
  color: #5c5c5c;
  flex-direction: row;
  align-items  : center;
  .description{
    margin-left: 3rem;
  }
`

export const Alert = styled.div`
  width: 60%;
  padding: 3rem;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 1rem;
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
  align-items: center;
  justify-content: center;
  background-color: yellow;
`









import styled from 'styled-components'
import background from '../../assets/fundo_conibase.png'

export const Button = styled.button`
  width: 308px;
  height: 51px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 100px;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  color: #001375;
  z-index: 10;
`;

export const Div = styled.div`
  display: flex;
  z-index: 10;
   @media (min-width: 325px) and (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 10;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-image: url(${background});
`;

export const ImgVetor1 = styled.img`
  height: 20vh;
  margin-left: auto;
  margin-bottom: auto;
`;

export const ImgVetor2 = styled.img`
  height: 20vh;
  margin-right: auto;
  margin-top: auto;
`;

export const Logo = styled.img`

`;

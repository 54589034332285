import { createGlobalStyle } from 'styled-components';
import fundoConibase from '../assets/fundo_conibase.png'

const Global = createGlobalStyle`
  body{
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    background-image: url(${fundoConibase});
  }
  #root{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  html{
    scroll-behavior: smooth;
  }
`;

export default Global;

import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Login from './pages/login';
import Menu from './pages/menu';
import RH from './pages/RH';
import Compras from './pages/Compras';
import CSV from './pages/CSV';
import { useGlobalContext } from './context/globalContext';
import GlobalShowcase from './pages/GlobalShowcase';

const PrivateRoute = ({ component: Component, ...rest }) => {

  const { isAuthenticated } = useGlobalContext()
  console.log(isAuthenticated)
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  )
};



export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <PrivateRoute  path="/menu" component={Menu} />
        <PrivateRoute  path="/rh" component={RH} />
        <PrivateRoute  path="/compras" component={Compras} />
        <PrivateRoute  path="/upload" component={CSV} />
        <PrivateRoute  path="/global" component={GlobalShowcase} />
      </Switch>
    </BrowserRouter>
  )
}

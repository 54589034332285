import axios from 'axios';


const api = axios.create({
  baseURL: 'https://apiconibase.azurewebsites.net',
})

export default api;

// https://apiconibase.azurewebsites.net

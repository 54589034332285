import ReactDOM from 'react-dom';
import React from 'react';
import Routes from './routes';
import Global from './styles/global';
import ContextProvider from './context/globalContext'

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <Routes />
      <Global />
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

import React, { useState } from 'react';
import Modal from 'react-modal';
import { Container, Content, CloseModalButton, Image, TextModal, InstructionButton, FileButton, SendButton } from './styles';
import {GrClose} from 'react-icons/gr';
import img1 from '../../assets/titulo_duplicado.png';
import img2 from '../../assets/5_culunas.png';
import img3 from '../../assets/tipo_dado.png';
import api from '../../services/api';

const CSV = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState();

  const style = {
    content:{
      top: '25%',
      left: '25%',
      right: 'auto',
      bottom: 'auto',
      width: '50%',
      height: '50%'
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.2)'
    },
  }

  const inputDisplay = {
    display: 'none',
  }

  const token = localStorage.getItem('token');

  const handleFileSubmit = async (e) => {
    try {
      if(!file) alert("Você precisa selecionar um arquivo para enviar!")
      const formData = new FormData();
      formData.append('file', file, file.name);

      const response = await api.post('/user/csv', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type' : 'multipart/form-data'
        }
      })
      if(response.status === 200){
        alert("Dados inseridos com sucesso!")
      }
    } catch (err) {
      console.log(err)
    }

  }

  return (
    <Container>
      <Modal style={style} isOpen={isOpen} onRequestClose={ () => setIsOpen(false)}>
        <CloseModalButton>
          <GrClose size={20} color="#c4c4c4" onClick={() => setIsOpen(false)} />
        </CloseModalButton>
          <TextModal>1. Remover todas as divisões por loja, como no exemplo abaixo: </TextModal>
          <Image src={img1} alt="Titulo Duplicado" />
          <TextModal>2. Devem existir apenas 5 colunas, como no exemplo abaixo: </TextModal>
          <Image src={img2} alt="5 colunas" />
          <TextModal>3. As colunas devem conter o nome e valores como no exemplo abaixo: </TextModal>
          <Image src={img3} alt="Valores" />
      </Modal>

      <Content>
        <p>Atualizar funcionario</p>
        <InstructionButton  onClick={() => setIsOpen(true)} >Instruções</InstructionButton>
        <input onChange={(e) => setFile(e.target.files[0])} style={inputDisplay} type="file" id="file" />
        <FileButton htmlFor="file">Selecionar Arquivo</FileButton>
        <SendButton onClick={handleFileSubmit} >Enviar</SendButton>
      </Content>
    </Container>
  );
}

export default CSV;

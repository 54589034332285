import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
export const Manager = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 8rem;
  background-color: #F0F0F0;
  box-shadow:5px 5px 10px lightgray;
`

export const Buttom = styled.button`
  border-radius: 20px;
  background-color: #DE0209;
  color: #FFFFFF;
  width: 10%;
  padding: .5rem;
  border: none;
  font-size: 1.3rem;
  margin-right: 5rem;
`

export const RaterButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: flex-start;
  border: none;
  background-color: ${({red}) => red ? '#DE0209' : '#001375'};
  color: #FFF;
  border-radius: 20px;
  width: 15%;
  padding: .4rem 0 .4rem 1%;
  input{
    border: none;
    width: 100%;
    background: transparent;
    color: white;
    font-weight: 500;
    font-size: 1rem;
    outline: none;
    &::placeholder{
      color: white;
      font-weight: 500;
      font-size: 1rem;
      opacity: 1;
    }
  }
  .autocomplete{
    background-color: white;
    width: 100%;
    position: absolute;
    color: black;
    display: flex;
    flex-direction: column;
    font-size: .8rem;
    bottom: -2rem;
    box-sizing: border-box;
    top: 2.2rem;
    left: 0;
    .unique{
      padding: .5rem .2rem;
      background-color: white;
      &:hover{
        filter: brightness(.8)
      }
    }
  }
`

export const Select = styled.select`
  width: 10%;
  border: none;
  background-color: #001375;
  padding: .5rem 0;
  color: #FFF;
  border-radius: 20px;
`
export const Range = styled.input.attrs({
  type: 'range',
  min: '0',
  max: '10',
})`
 -webkit-appearance: none;
 background: #C4C4C4;
 outline: none;
 height: .4rem;
 border-radius: 20px;
 opacity: 0.7;
 -webkit-transition: .2s;
  transition: opacity .2s;
  &:hover{
    opacity: 1;
  }

 &::-moz-range-thumb {
   background: #001375;
   border:none;
 }
`

export const RangeValue = styled.div`
  border: 2px solid #001375;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  width: 3rem;
  height: 1rem;
  text-align: center;
  color: #001375;
  font-weight: 600;
`

export const Assessment = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;
  margin-top: 3rem;
  width: 70%;
  height: 10rem;
  background-color: #F0F0F0;
  border-radius: 10px;
  box-shadow: 3px 3px 10px lightgray ;
`
export const Avaliador = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  .title{
    font-size: 1.5rem;
    font-weight: 500;
    color: #001375;
  }
  font-weight: 500;
    color: #5c5c5c;
`
export const Avaliado = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  .title{
    font-size: 1.5rem;
    font-weight: 500;
    color: #001375;
  }
  div{
    font-weight: 500;
    color: #5c5c5c;
  }
`
export const Nota = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  .title{
    font-size: 1.5rem;
    font-weight: 500;
    color: #001375;
  }
  div{
    font-size: 3rem;
    font-weight: 500;
    color: #5c5c5c;
  }
`
export const Comentario = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  .title{
    font-size: 1.5rem;
    font-weight: 500;
    color: #001375;
  }
  div{
    font-weight: 500;
      color: #5c5c5c;
  }
`

import styled from 'styled-components';
import logoBranco from '../../img/logo_branco.png';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #001375;
  width: 100%;
  height: 5rem;
`
export const Image = styled.img.attrs({
  src: logoBranco,
  alt: "Logo Conibase"
})`
  width: 10rem;
  margin-left: 5rem;
`

export const Buttom = styled.button`
  border-radius: 20px;
  background-color: #DE0209;
  color: #FFFFFF;
  width: 10%;
  padding: .5rem;
  border: none;
  font-size: 1.3rem;
  margin-right: 5rem;
`

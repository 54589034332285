import styled from 'styled-components'

export const Form = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.75em;
  padding: 0.25rem 1rem;
  width: 308px;
  height: 51px;
  background: rgba(0, 19, 117, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 100px;
  margin-bottom: 15px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

export const Input = styled.input`
 padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-align: center;
  border: 0;
  border-radius: 2rem;
  background-color: transparent;
  margin-right: 65px;
  outline: none;
  z-index: 10;
`;

export const Button = styled.button`
  width: 114px;
  height: 37px;
  left: 663px;
  top: 652px;
  background: #de0209;
  border-radius: 100px;
  border-style: none;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  z-index: 10;
`;



export const Logo = styled.img`

`;

export const FormImg = styled.img`
  width: 20px;
  margin-inline-end: auto;
  margin-left: 15px;
`;

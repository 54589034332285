import styled from 'styled-components'

export const ImgVetor1 = styled.img`
  height: 40rem;
  opacity: 0.2;
  position: absolute;
  right: 0;
  top: 0;
  @media(max-width: 800px){
    height: 20rem;
  }
`;

export const ImgVetor2 = styled.img`
  height: 30rem;
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0.2;
  @media(max-width: 800px){
    height: 15rem;
  }
`;

export const Container = styled.div`

`

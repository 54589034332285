import React, { useState } from 'react';
import Header from '../../components/header';
import { Container, Manager, RaterButton, Select, Range, RangeValue, Buttom, Assessment,
  Avaliado, Avaliador, Nota, Comentario
} from './styles';
import { HiOutlineUser, HiOutlineCloudDownload } from 'react-icons/hi';
import api from '../../services/api';
import fileDownload from 'js-file-download';

function RH() {

  const [raters, setRaters] = useState([]);
  const [selectedRater, setSelectedRater] = useState('');
  const [rateds, setRateds] = useState([]);
  const [selectedRated, setSelectedRated] = useState('');

  const [data, setData] = useState([]);

  const [filter, setfilter] = useState('');

  const [range, setRange] = useState(0);

  const token = localStorage.getItem('token');


  const handleCSV = async () => {
    try {

      const response = await api.post("assessment/download", data, {headers:{'Authorization': `Bearer ${token}`}}, {responseType: 'blob'});
      fileDownload(response.data, `report-assessment-${new Date().toISOString()}.csv`)
    } catch (err) {
      alert(err)
    }
  }

  const handleSubmit = async() => {
    try {
      if(filter === ''){
        alert('Para realizar uma pesquisa vocês precisa pelo menos preencher um filtro e uma nota!')
        return
      }
      const response = await api.get(`/assessment/filter?filter=${filter}&note=${range}&rater=${selectedRater}&rated=${selectedRated}`, {headers:{'Authorization': `Bearer ${token}`}})
      setData(response.data);
    } catch (err) {
      alert(err.response.data.error);
    }
  }


  const handleRater = async (value) => {

    setSelectedRater(value);
    if(value.length > 3){
      const response = await api.get(`/users?name=${value}`, {headers:{'Authorization': `Bearer ${token}`}});
    setRaters(response.data)
    }
    if(value === '') {
      setRaters([]);
    }
  }

  const handleRated = async (value) => {
    setSelectedRated(value);
    if(value.length > 3){
      const response = await api.get(`/users?name=${value}`, {headers:{'Authorization': `Bearer ${token}`}});
      setRateds(response.data)
    }
    if(value === '') {
      setRateds([]);
    }
  }

  return (
    <Container>
      <Header />
      <Manager>

      <RaterButton>
        <HiOutlineUser />
        <input value={selectedRater} type="text" placeholder="Avaliador" onChange={(e) => handleRater(e.target.value)} />
        {raters.length !== 0 && (
          <div className="autocomplete">
          {raters.map(rater => (
              <div className="unique" key={rater.id} onClick={() => {setSelectedRater(rater.name); setRaters('')}}>{rater.name}</div>
              ))}
          </div>
        )}

      </RaterButton>


      <RaterButton red >
        <HiOutlineUser />
        <input value={selectedRated} type="text" placeholder="Avaliado" onChange={(e) => handleRated(e.target.value)} />
        {rateds.length !== 0 && (
          <div className="autocomplete">
          {rateds.map(rater => (
              <div className="unique" key={rater.id} onClick={() => {setSelectedRated(rater.name); setRateds('')}}>{rater.name}</div>
              ))}
          </div>
        )}
      </RaterButton>

      <Select onChange={(e) => setfilter(e.target.value)}>
        <option value="" >Selecione um filtro</option>
        <option value="equal" >Igual</option>
        <option value="bigger" >Maior</option>
        <option value="less" >Menor</option>
      </Select>

      <Range value={range} onChange={(e) => setRange(e.target.value)} />
      <RangeValue>{range}</RangeValue>

      <Buttom onClick={handleSubmit}>Gerar</Buttom>
      <HiOutlineCloudDownload size={30} color="#DE0209" onClick={handleCSV} />
      </Manager>
      {data && data.map(item => (
        <Assessment key={item.id}>
        <Avaliador>
          <div className="title">Avaliador</div>
        <div>{item.rater}</div>
        <div>{item.rater_store}</div>
        </Avaliador>
        <Avaliado>
        <div className="title">Avaliado</div>
        <div>{item.rated}</div>
        <div>{item.rated_store}</div>
        </Avaliado>
        <Nota>
        <div className="title" >Nota</div>
        <div>{item.note}</div>
        </Nota>
        <Comentario>
        <div className="title">Comentario</div>
        <div>{item.coments}</div>
        </Comentario>
      </Assessment>
      ))}

    </Container>
  );
}

export default RH;


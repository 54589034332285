import React, { useEffect, useState } from 'react';
import Header from '../../components/header';
import { HiOutlineCloudDownload } from 'react-icons/hi';
import {  Manager, Select, Buttom, Showcase } from './style';
import api from '../../services/api';
import fileDownload from 'js-file-download';

function GlobalShowcase() {

  const [stores, setStores] = useState([]);
  const [dates, setDates] = useState([]);
  const [data, setData] = useState([]);
  const [SelectedStore, setSelectedStore] = useState();
  const [SelectedDate, setSelectedDate] = useState();

  const token = localStorage.getItem('token');


  useEffect(() => {

    const getStores = async () => {
      try {
        const response = await api.get('/showcase/stores', {headers:{'Authorization': `Bearer ${token}`}});
        setStores(response.data)
       } catch (err) {
         if(err.response) alert(err.response.data.error);
         else alert(err)
       }
    }
    getStores()
  },[token])

  const handleDownload = async () => {
    const response = await api.post('/showcase/download', data, {headers:{'Authorization': `Bearer ${token}`}});
    fileDownload(response.data, `report-showcase-${new Date().toISOString()}.csv`)
  }

  const handleSubmit =async () => {

    try {
      console.log(SelectedDate, SelectedStore)
      const response = await api.get(`/showcase/global/filter?store=${SelectedStore}&date=${SelectedDate}`, {headers:{'Authorization': `Bearer ${token}`}});
    console.log(response.data)
    setData(response.data)
    } catch (err) {
      alert(JSON.stringify(err.response.data))
    }
  }

  const getDates = async () => {
    try {
      if(!SelectedStore) {
        alert('Você precisa selecionar uma loja para selecionar uma data!')
        return
      }
     const response = await api.get(`/showcase/global/dates?storeId=${SelectedStore}`, {headers:{'Authorization': `Bearer ${token}`}});

     setDates(response.data)
    } catch (err) {
      if(err.response) alert(err.response.data.error);
      else alert(err)
    }
   }

  return (
    <>
    <Header/>
    <Manager>

      <Select onChange={(e) => setSelectedStore(e.target.value)}>
        <option value="" >Selecione uma loja</option>
        {stores && stores.map(store => (
          <option key={store.is_store} value={store.id_store} >{store.store}</option>
        ))}
      </Select>

      <Select onChange={(e) => setSelectedDate(e.target.value)} onClick={getDates}>
        <option value="" >Selecione uma data</option>
        {SelectedStore && dates.map(date => (
          <option key={date} value={date}>{date}</option>
        ))}
      </Select>



      <Buttom onClick={handleSubmit} >Gerar</Buttom>

      <HiOutlineCloudDownload size={30} color="#DE0209" onClick={handleDownload} />

      </Manager>
      {data && data.map((item, index) => (
        <Showcase key={index}>
        <div>{item.codeint}</div>
        <div className="description">{item.description}</div>
        </Showcase>
      ))}
      </>
  );
}

export default GlobalShowcase;

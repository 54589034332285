import { createContext, useContext } from 'react';
import api from '../services/api';
import base64 from 'base-64';
const Context = createContext();

const { Provider } = Context;

const ContextProvider = ({children}) => {


  const logIn = async (data, history) => {
    try {
      const response = await api.post('/session', data);
      const token = response.data.token;
      const res = JSON.parse(base64.decode(token.split('.')[1]));
      localStorage.setItem('token', token);
      localStorage.setItem('permissions', JSON.stringify(res.permissions));
      localStorage.setItem('id', res.id);
      history.push('/menu');
    } catch (err) {
      alert(err.response?.data.error)
    }

  }

  const logOut = (history) => {
    localStorage.clear();
    history.push('/')
  }

  const isAuthenticated = () => localStorage.getItem('token') !== null;

  return (
    <Provider value={{logIn, isAuthenticated, logOut}}>
      {children}
    </Provider>
  )
}

export const useGlobalContext = () => {
  const context = useContext(Context)
  if(!context) throw new Error('Esse componente não esta abaixo do provider!')
  return context
}

export default ContextProvider;

import React from 'react';
import {useGlobalContext} from '../../context/globalContext';
import { useHistory } from 'react-router-dom'
import {  Container, Image, Buttom } from './style';

function Header() {

  const { logOut } = useGlobalContext();
  const history = useHistory();

  const handlebuttonPress = () => {
    logOut(history);
  }
  return (
    <Container>
      <Image />
      <Buttom onClick={handlebuttonPress}> Sair </Buttom>
    </Container>
  );
}

export default Header;

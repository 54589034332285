import { useState } from 'react';
import logo from '../../assets/logo_conibase.png';
import users from '../../assets/user.svg'
import Background from '../../components/background';
import { useGlobalContext } from '../../context/globalContext';
import {Form, Container, Input, Button, Logo, FormImg} from './styles';
import { useHistory } from 'react-router-dom';

function Login() {

  const {logIn} = useGlobalContext();
  const [cpf, setCpf] = useState('')

  const history = useHistory();

  const handleLogin = () => {

    const data = {
      cpf: cpf.replace(/\D/g ,'')
    }
    logIn(data, history)
  }

  const maskCPF = (cpf) => {
    return cpf.replace(/\D/g, '')
    .replace(/^(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
  }

  return (
    <Background>
      <Container>
        <Logo src={logo} alt="logo" />
        <div>
          <Form>
            <FormImg src={users} alt="input user"></FormImg>
            <Input onKeyPress={(e) => {
              if(e.key === 'Enter'){
                handleLogin()
              }
            }} value={cpf} onChange={(e) => {
              setCpf(maskCPF(e.target.value))
            }} type="text"  placeholder="CPF"/>
          </Form>
        </div>
        <Button onClick={handleLogin} >
          Login
        </Button>
      </Container>
    </Background>
  );
}

export default Login;

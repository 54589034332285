import React from 'react';
import vetor1 from '../../assets/vetor-sup-direito.svg'
import vetor2 from '../../assets/vetor-inf-esquerdo.svg'
 import { ImgVetor1, ImgVetor2, Container } from './styles';

function Background({children}) {
  return(
    <Container>
      <ImgVetor1 src={vetor1} />
      <ImgVetor2 src={vetor2} />
      {children}
    </Container>
  );
}

export default Background;
import logo from '../../assets/logo_conibase.png';
import {Button, Container, Logo, Div} from './styles'
import Background from '../../components/background';
import { useHistory } from 'react-router-dom';

function Menu() {

  const history = useHistory();

  const permissions = JSON.parse(localStorage.getItem('permissions'));
  console.log("permissoes", permissions)

  const [hasShoppingPermission] = permissions.filter(item => item.permission === 'Compras');
  const [hasRHPermission] = permissions.filter(item => item.permission === 'RH');
  const [hasADMPermission] = permissions.filter(item => item.permission === 'ADM');

  console.log(hasRHPermission)

  const MenuItem = () => {
    if(hasADMPermission) {
      return (
        <Div>
          <Button onClick={() => history.push('/rh')}><b>RH</b></Button>
          <Button onClick={() => history.push('/compras')}><b>COMPRAS</b></Button>
          <Button onClick={() => history.push('/upload')}><b>FUNCIONARIOS</b></Button>
          <Button onClick={() => history.push('/global')}><b>RELATORIOS MOSTRUARIO</b></Button>
        </Div>
      )
    }
    if(hasRHPermission && !hasShoppingPermission) {
      return (
        <Div>
          <Button onClick={() => history.push('/rh')}><b>RH</b></Button>
          <Button onClick={() => history.push('/upload')}><b>FUNCIONARIOS</b></Button>
        </Div>
      )
    }
    if(!hasRHPermission && hasShoppingPermission) {
      return (
        <Div>
          <Button onClick={() => history.push('/compras')}><b>COMPRAS</b></Button>
          <Button onClick={() => history.push('/global')}><b>RELATORIOS MOSTRUARIO</b></Button>
        </Div>
      )
  }
  return(<div></div>)
  }


  return (
    <Background>
      <Container>
        <Logo src={logo} alt="logo" />
        <MenuItem />
      </Container>
    </Background>
  );
}

export default Menu;
